import loginModule from "../loginModule.js";

function verifyEmailController($scope, eventStreamService, loginService, loginConstants, languageResource, injectedData) {
    "ngInject";
    $scope.layout = {};
    
    $scope.init = function () {
        $scope.layout.headerText = languageResource.get("Label.EmailNeedsVerification");
        eventStreamService.sendModalShownEvent(injectedData.context + loginConstants.verifyEmailContextSuffix);
    }

    $scope.sendVerificationEmail = function () {
        $scope.layout.error = null;
        $scope.layout.isSubmitBusy = true;
        var params = {
            credentialType: loginConstants.credentialTypes.email,
            credentialValue: injectedData.email,
            password: injectedData.password
        }

        loginService.verifyCredentials(params)
            .then(
                function () {
                    $scope.layout.headerText = languageResource.get("Label.VerificationEmailSent");;
                    $scope.layout.emailSent = true;
                    $scope.sendSendVerificationEmailEvent(true);
                },
                function (result) {
                    var errorCode = result && result.data && result.data.errors && result.data.errors.length > 0 && result.data.errors[0].code;

                    switch (errorCode) {
                        case loginConstants.sendVerificationEmailErrorCodes.tooManyAttempts:
                            $scope.layout.error = languageResource.get("Response.TooManyAttemptsPleaseWait");
                            break;
                        default:
                            $scope.layout.error = languageResource.get("Message.UnknownErrorTryAgain");
                    }

                    $scope.sendSendVerificationEmailEvent(false);
                }
            ).finally(function () {
                $scope.layout.isSubmitBusy = false;
            });
    }

    $scope.sendSendVerificationEmailEvent = function (wasSuccessful, message) {
        eventStreamService.sendEventWithTarget(
            eventStreamService.eventNames.account.sendVerificationEmail,
            injectedData.context + loginConstants.verifyEmailContextSuffix,
            {
                success: wasSuccessful,
                msg: message
            }
        );
    }

    $scope.init();
}

loginModule.controller("verifyEmailController", verifyEmailController);

export default verifyEmailController;