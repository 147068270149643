/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
import angular from 'angular';
import {
  CrossDeviceLoginDisplayCodeService,
  EnvironmentUrls,
  FormEvents,
  ReactLoginService,
  ReactIdentityVerificationService
} from 'Roblox';
import loginModule from '../loginModule';

function loginFormController(
  $scope,
  $injector,
  $window,
  $timeout,
  $httpParamSerializerJQLike,
  $uibModal,
  urlService,
  languageResource,
  loginService,
  loginConstants,
  urlConstants,
  systemFeedbackService,
  modalService
) {
  'ngInject';

  $scope.loginFormData = {
    forgotCredentialsUrl: EnvironmentUrls.websiteUrl + loginConstants.urls.forgotCredentials,
    homeUrl: EnvironmentUrls.websiteUrl + loginConstants.urls.home,
    loginPageUrl: EnvironmentUrls.websiteUrl + loginConstants.urls.login
  };
  $scope.loginLayout = {
    isFirstSubmit: true
  };
  $scope.forms = {};
  $scope.loginParams = $scope.loginParams || {};
  $scope.crossDeviceLoginParams = {};

  $scope.securityQuestionParameter = null;

  $scope.numberOfFailedTwoStepVerificationChallengeAttempts = 0;

  const watchParams = $scope.$watch(
    function () {
      return $scope.loginParams;
    },
    function (newValue) {
      if (newValue) {
        const errorCode = ReactLoginService.utils.getUrlParamValue(loginConstants.urlQueryNames.errorCode);

        if (errorCode && !$scope.loginParams.redirectToLoginPageOnError) {
          const ctype = ReactLoginService.utils.getUrlParamValue(loginConstants.urlQueryNames.cType);

          $scope.loginFormData.credentialValue = ReactLoginService.utils.getUrlParamValue(
            loginConstants.urlQueryNames.username
          );
          $scope.setEnteredCredentialType(ctype);
          $scope.loginLayout.error = $scope.getErrorMessage(parseInt(errorCode, 10));
        } else {
          $scope.loginFormData.credentialValue = null;
        }
      }
    },
    true
  );

  window.addEventListener(
    'OnCrossDeviceCodeValidated',
    event => {
      $scope.crossDeviceLoginParams.code = event.detail.code;
      $scope.crossDeviceLoginParams.enteredCredentialType = event.detail.ctype;
      $scope.crossDeviceLoginParams.privateKey = event.detail.privateKey;
      $scope.setEnteredCredentialType(event.detail.ctype);
      $scope.loginLayout.isProcessing = true;
      $scope.login();
    },
    false
  );

  $window.addEventListener(
    'ReactCaptchaSuccess',
    event => {
      const captchaData = event.detail.data;
      $scope.handleCaptchaSuccess(captchaData);
    },
    false
  );

  $window.addEventListener(
    'ReactCaptchaError',
    event => {
      const errorCode = event.detail.errorCode;
      $scope.handleCaptchaError(errorCode);
    },
    false
  );

  $scope.$on('$destroy', function () {
    watchParams();
  });

  // eslint-disable-next-line no-unused-vars
  $scope.init = function (captchaId) {
    ReactLoginService.getAuthTokenMetadata().then(function (data) {
      $scope.crossDeviceLoginParams.IsLoginCodeButtonDisplayed = data?.IsLoginCodeButtonDisplayed;
      $scope.$apply();
    });
    $scope.loginLayout.isProcessing = false;
  };

  $scope.handleCaptchaError = function (errorCode) {
    const loginErrorCode = ReactLoginService.utils.getLoginErrorCodeFromCaptchaErrorCode(errorCode);
    
    $timeout(function () {
      $scope.loginLayout.isProcessing = false;
      $scope.setErrorMessage(loginErrorCode, true);
    });
  };

  $scope.handleCaptchaSuccess = function (captchaData) {
    $scope.loginLayout.forceCaptcha = false;
    const isCrossDeviceLogin =
      $scope.loginFormData.enteredCredentialType === loginConstants.credentialTypes.authToken;
    if (isCrossDeviceLogin) {
      $scope.login(captchaData);
    } else {
      $scope.submit(false, captchaData);
    }
  };

  $scope.setEnteredCredentialType = function (credentialType) {
    if (credentialType) {
      $scope.loginFormData.enteredCredentialType = credentialType;
      return;
    }

    if (ReactLoginService.utils.isValidEmail($scope.loginFormData.credentialValue)) {
      $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.email;
      return;
    }

    if (ReactLoginService.utils.isPhoneNumber($scope.loginFormData.credentialValue)) {
      $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.phone;
      return;
    }

    $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.username;
  };

  $scope.login = function (captchaData) {
    const didUserEnterEmail = ReactLoginService.utils.isValidEmail($scope.loginFormData.credentialValue);
    const didUserEnterPhone = ReactLoginService.utils.isPhoneNumber($scope.loginFormData.credentialValue);

    const isCrossDeviceLogin =
      $scope.loginFormData.enteredCredentialType === loginConstants.credentialTypes.authToken;

    if (!$scope.loginFormData.isDefaultLoginRequired && !isCrossDeviceLogin) {
      // if user entered in text containing "@", assume they are trying to log in with email
      if (didUserEnterEmail) {
        $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.email;
      } else if (didUserEnterPhone) {
        $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.phone;
      }
    }

    // remove the error banner if they are now trying to log in with username
    if (!didUserEnterEmail && !didUserEnterPhone) {
      systemFeedbackService.clear();
    }

    // update login params based on ctype.
    const cvalue = isCrossDeviceLogin
      ? $scope.crossDeviceLoginParams.code
      : $scope.loginFormData.credentialValue;
    const ctype = isCrossDeviceLogin
      ? $scope.crossDeviceLoginParams.enteredCredentialType
      : $scope.loginFormData.enteredCredentialType;
    const password = isCrossDeviceLogin
      ? $scope.crossDeviceLoginParams.privateKey
      : $scope.loginFormData.password;

    const loginParams = {
      cvalue,
      ctype,
      password
    };

    if (captchaData != null) {
      loginParams.captchaId = captchaData.captchaId;
      loginParams.captchaToken = captchaData.captchaToken;
      loginParams.captchaProvider = captchaData.captchaProvider;
    }

    if ($scope.securityQuestionParameter !== null) {
      loginParams.securityQuestionSessionId = $scope.securityQuestionParameter.sessionId;
      loginParams.securityQuestionRedemptionToken =
        $scope.securityQuestionParameter.redemptionToken;
    }

    loginService.login(loginParams).then(
      function (response) {
        ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.success);
        const { data } = response;
        let twoStepVerificationToken;

        if (data && data.tl) {
          twoStepVerificationToken = data.tl;
        } else if (data && data.twoStepVerificationData) {
          twoStepVerificationToken = data.twoStepVerificationData.ticket
        }

        if (twoStepVerificationToken) {
          $scope.userId = data.user.id;
          $scope.challengeId = twoStepVerificationToken;
          let inputParams = {
            userId: data.user.id,
            challengeId: twoStepVerificationToken,
            onChallengeCompleted: $scope.receiveTwoStepChallengeToken,
            onChallengeInvalidated: $scope.handleInvalidatedTwoStepVerificationChallenge,
            onModalChallengeAbandoned: $scope.on2svModalChallengeAbandoned,
            onUnknownError: $scope.showUnknownError
          };
          let event = new CustomEvent('render2svChallenge', {
            detail: {
              inputParams
            }
          });
          window.dispatchEvent(event);
        } else if (data && data.identityVerificationLoginTicket) {
          ReactIdentityVerificationService.storeIdentityVerificationLoginTicket(data.identityVerificationLoginTicket);
          $scope.showIdentityVerificationNoticeModal();
        } else {
          $window.location.href = $scope.getRedirectUrl(data);
        }
      },
      function (result) {
        $scope.handleLoginError(result.data, result.status);
      }
    );
  };

  $scope.on2svModalChallengeAbandoned = () => {
    $timeout(function () {
      $scope.loginLayout.isProcessing = false;
    });
  };

  $scope.handleInvalidatedTwoStepVerificationChallenge = () => {
    $scope.numberOfFailedTwoStepVerificationChallengeAttempts += 1;
    $scope.loginLayout.error = languageResource.get('Response.VerificationError');
    if ($scope.numberOfFailedTwoStepVerificationChallengeAttempts < loginConstants.retryAttempts.maxInvalidatedChallengeAttempts) {
      $scope.login();
    } else {
      $scope.loginLayout.error = languageResource.get('Response.VerificationError');
    }
  };

  $scope.receiveTwoStepChallengeToken = tokenData => {
    const loginWith2svTokenParams = {
      challengeId: $scope.challengeId,
      verificationToken: tokenData.verificationToken,
      rememberDevice: tokenData.rememberDevice
    };
    ReactLoginService.loginWithVerificationToken($scope.userId, loginWith2svTokenParams).then(response => {
      if (response) {
        // handle redirect after login
        let twoStepVerificationReturnUrl = urlService.getAbsoluteUrl('/');
        if (response.identityVerificationLoginTicket) {
          ReactIdentityVerificationService.storeIdentityVerificationLoginTicket(data.identityVerificationLoginTicket);
          $scope.showIdentityVerificationNoticeModal();
        } else if ($scope.loginParams.returnUrl) {
          twoStepVerificationReturnUrl = $scope.loginParams.returnUrl;
          $window.location.href = twoStepVerificationReturnUrl;
        } else if (!$scope.loginParams.redirectToHomePostLogin) {
          twoStepVerificationReturnUrl = $scope.getCurrentLocation();
          $window.location.href = twoStepVerificationReturnUrl;
        } else {
          $window.location.href = twoStepVerificationReturnUrl;
        }
      } else {
        $scope.loginLayout.error = languageResource.get('Response.VerificationError');
      }
    })
    .catch(() => {
      $scope.showUnknownError();
    });
  };
  $scope.handleLoginError = function (result, status) {
    if (status === loginConstants.statusCodes.flooded) {
      $scope.setErrorMessage(loginConstants.errorCodes.tooManyAttempts, true);
      return;
    }
    const errorCode = result && result.errors && result.errors.length > 0 && result.errors[0].code;

    // eslint-disable-next-line default-case
    switch (errorCode) {
      case loginConstants.errorCodes.captcha:
        // TODO: refactor into a util function
        let dxBlob = '';
        let captchaId = '';
        if (result.errors && result.errors.length > 0 && result.errors[0].fieldData) {
          const captchaData = result.errors[0].fieldData;
          let jsonData = '';
          if (captchaData != null) {
            try {
              jsonData = JSON.parse(captchaData);
              dxBlob = jsonData.dxBlob;
              captchaId = jsonData.unifiedCaptchaId;
            } catch (e) {
              // backward compatible with old version that returns string only.
              dxBlob = captchaData;
            }
          }
        }

        // construct captchaDataOptions
        const inputParams = { unifiedCaptchaId: captchaId, dataExchange: dxBlob };
        const event = new CustomEvent('CaptchaDataOptionsUpdated', {
          detail: {
            inputParams
          }
        });
        window.dispatchEvent(event);
        $scope.activateCaptcha(true);
        $scope.loginLayout.isProcessing = false;
        ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.captcha);
        return;
      case loginConstants.errorCodes.passwordResetRequired:
        ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.passwordResetRequired);
        ReactLoginService.utils.navigateToSecurityNotificationPage();
        return;
      case loginConstants.errorCodes.securityQuestionRequired:
        ReactLoginService.utils.incrementEphemeralCounter(
          loginConstants.counters.securityQuestionRequired
        );
        let userId = '';
        let sessionId = '';
        if (result.errors && result.errors.length > 0 && result.errors[0].fieldData) {
          const securityQuestionResultData = result.errors[0].fieldData;
          let jsonData = '';
          if (securityQuestionResultData != null) {
            try {
              jsonData = JSON.parse(securityQuestionResultData);
              userId = jsonData.userId;
              sessionId = jsonData.sessionId;
            } catch (e) {
              // No-op.
            }
          }
        }
        // Not expected to happen if we get this error code.
        if (userId === '' || sessionId === '') {
          $scope.loginLayout.isProcessing = false;
          $scope.showUnknownError();
          return;
        }
        $scope.launchSecurityQuestions(userId, sessionId);
        return;
    }
    if (
      errorCode === loginConstants.errorCodes.unverifiedCredentials &&
      $scope.loginFormData.enteredCredentialType === loginConstants.credentialTypes.email
    ) {
      ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.unverifiedAccount);

      const canVerifyCredentialsParams = {
        credentialType: loginConstants.credentialTypes.email,
        credentialValue: $scope.loginFormData.credentialValue,
        password: $scope.loginFormData.password
      };

      ReactLoginService.canVerifyCredentials(canVerifyCredentialsParams).then(
        function (response) {
          if (response && response.canSend) {
            $scope.promptUserToVerifyEmail();
            $scope.loginLayout.isProcessing = false;
          } else {
            $scope.setErrorMessage(errorCode);
          }
        },
        function () {
          $scope.setErrorMessage(errorCode);
        }
      );
    } else {
      // this is handling most of the error msg setting
      $scope.setErrorMessage(errorCode, true);
    }
  };

  $scope.promptUserToVerifyEmail = function () {
    $uibModal
      .open({
        backdrop: 'static',
        templateUrl: loginConstants.templates.verifyEmail,
        controller: loginConstants.controllers.verifyEmail,
        resolve: {
          injectedData: {
            context: $scope.loginParams.context,
            email: $scope.loginFormData.credentialValue,
            password: $scope.loginFormData.password
          }
        }
      })
      .result.then(angular.noop, angular.noop);
  };

  $scope.setErrorMessage = function (errorCode, incrementFailureCounter) {
    const usernameLoginRequired =
      errorCode === loginConstants.errorCodes.defaultLoginRequired ||
      errorCode === loginConstants.errorCodes.unverifiedCredentials;

    if (
      $scope.loginParams.redirectToLoginPageOnError ||
      ($scope.loginParams.redirectToLoginPageWhenUsernameIsRequired && usernameLoginRequired)
    ) {
      const params = {
        errorCode,
        ctype: $scope.loginFormData.enteredCredentialType
      };

      if (!usernameLoginRequired) {
        params.username = $scope.loginFormData.credentialValue;
      }

      ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.redirectToLogin);
      $window.location.href =
        $scope.loginFormData.loginPageUrl +
        urlConstants.urlQueryStringPrefix +
        $httpParamSerializerJQLike(params);
      return;
    }

    $scope.loginLayout.isProcessing = false;
    $scope.loginLayout.error = $scope.getErrorMessage(errorCode, incrementFailureCounter);
  };

  // function to get error message from errorCode
  $scope.getErrorMessage = function (errorCode, incrementFailureCounter) {
    switch (errorCode) {
      case loginConstants.errorCodes.badCredentials:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.invalidCredentials);
        return $scope.getInvalidCredentialsErrorMessage();
      case loginConstants.errorCodes.accountNotFound:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.accountNotFound);
        return languageResource.get('Response.AccountNotFound');
      case loginConstants.errorCodes.accountIssue:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.accountIssue);
        return languageResource.get('Response.AccountIssueErrorContactSupport');
      case loginConstants.errorCodes.tooManyAttempts:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.tooManyAttempts);
        return languageResource.get('Response.TooManyAttemptsPleaseWait');
      case loginConstants.errorCodes.defaultLoginRequired:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.defaultLoginRequired);
        const defaultLoginRequiredMessage = $scope.getDefaultLoginRequiredErrorMessage();
        $scope.handleDefaultLoginRequired(defaultLoginRequiredMessage);
        return null;
      case loginConstants.errorCodes.unverifiedCredentials:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.unverifiedAccount);
        const unverifiedCredentialsMessage = $scope.getUnverifiedCredentialsErrorMessage();
        $scope.handleDefaultLoginRequired(unverifiedCredentialsMessage);
        return null;
      case loginConstants.errorCodes.captchaLoadFailed:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.captchaLoadFailed);
        return languageResource.get('Response.CaptchaErrorFailedToLoad');
      case loginConstants.errorCodes.captchaVerifyFailed:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.captchaVerifyFailed);
        return languageResource.get('Response.CaptchaErrorFailedToVerify');
      case loginConstants.errorCodes.luoBuUserDenied:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.luoBuUserDenied);
        return languageResource.get('Response.GlobalAppAccessError');
      case loginConstants.errorCodes.captchaUnknownError:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.captchaUnknownError);
        return languageResource.get('Message.UnknownErrorTryAgain');
      case loginConstants.errorCodes.screentimeRestricted:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.screentimeRestricted);
        return languageResource.get('Description.CurfewMessage');
      default:
        incrementFailureCounter &&
          ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.unknownError);
        return languageResource.get('Message.UnknownErrorTryAgain');
    }
  };

  $scope.activateCaptcha = function (forceCaptcha) {
    $scope.loginLayout.forceCaptcha = forceCaptcha;
    $scope.loginLayout.isProcessing = false;
  };

  $scope.getInvalidCredentialsErrorMessage = function () {
    switch ($scope.loginFormData.enteredCredentialType) {
      case loginConstants.credentialTypes.email:
        return languageResource.get('Response.IncorrectEmailOrPassword');
      case loginConstants.credentialTypes.phone:
        return languageResource.get('Response.IncorrectPhoneOrPassword');
      default:
        return languageResource.get('Response.IncorrectUsernamePassword');
    }
  };

  $scope.getDefaultLoginRequiredErrorMessage = function () {
    switch ($scope.loginFormData.enteredCredentialType) {
      case loginConstants.credentialTypes.email:
        return languageResource.get('Response.EmailLinkedToMultipleAccountsLoginWithUsername');
      case loginConstants.credentialTypes.phone:
        return languageResource.get('Response.LoginWithUsername');
      default:
        return '';
    }
  };

  $scope.getUnverifiedCredentialsErrorMessage = function () {
    switch ($scope.loginFormData.enteredCredentialType) {
      case loginConstants.credentialTypes.email:
        return languageResource.get('Response.UnverifiedEmailLoginWithUsername');
      case loginConstants.credentialTypes.phone:
        return languageResource.get('Response.UnverifiedPhoneLoginWithUsername');
      default:
        return '';
    }
  };

  // prompt users to use username and password
  $scope.handleDefaultLoginRequired = function (message) {
    $scope.loginFormData.isDefaultLoginRequired = true;
    $scope.loginFormData.credentialValue = null;
    $scope.loginFormData.password = null;

    if (!message) {
      return;
    }

    systemFeedbackService.warning(message);
  };

  $scope.getRedirectUrl = function (data) {
    const returnUrlValue = $scope.loginParams.returnUrl; // this is from html attr

    if (data && (data.userId || (data.user && data.user.id))) {
      if (returnUrlValue) {
        const returnUrl = $scope.addNewLoginQueryStringToUrl(returnUrlValue);
        return urlService.getAbsoluteUrl(returnUrl);
      }

      if ($scope.loginParams.redirectToHomePostLogin) {
        return (
          $scope.loginFormData.homeUrl +
          urlConstants.urlQueryStringPrefix +
          $httpParamSerializerJQLike({
            nl: true
          })
        );
      }

      if (data.identityVerificationLoginTicket) {
        ReactIdentityVerificationService.storeIdentityVerificationLoginTicket(data.identityVerificationLoginTicket);
        return urlService.getAbsoluteUrl(loginConstants.urls.koreaIdVerification);
      }

      return $scope.getCurrentLocation();
    }

    return urlService.getAbsoluteUrl('/');
  };

  $scope.getCurrentLocation = function () {
    let currentLocation = $window.location.href;
    const hashLocation = currentLocation ? currentLocation.indexOf(urlConstants.hashSign) : -1;

    if (hashLocation > -1) {
      currentLocation = currentLocation.slice(0, -1 * (currentLocation.length - hashLocation));
    }

    return $scope.addNewLoginQueryStringToUrl(currentLocation);
  };

  $scope.addNewLoginQueryStringToUrl = function (url) {
    if (!url) {
      return '/';
    }

    let result = url;
    if (url.indexOf(urlConstants.urlQueryStringPrefix) > -1) {
      result += urlConstants.urlQueryParameterSeparator;
    } else {
      result += urlConstants.urlQueryStringPrefix;
    }

    result += loginConstants.newLoginQueryString;
    return result;
  };

  $scope.sendInteractionClickEvent = function (fieldName) {
    if (FormEvents) {
      FormEvents.SendInteractionClick($scope.loginParams.context, fieldName);
    }
  };

  $scope.quickLogin = function () {
    $scope.sendInteractionClickEvent(loginConstants.quickLoginButtonName);
    CrossDeviceLoginDisplayCodeService.openModal();
  };

  $scope.showUnknownError = function () {
    $scope.loginLayout.error = languageResource.get('Message.UnknownErrorTryAgain');
  };

  // eslint-disable-next-line func-names
  $scope.launchSecurityQuestions = function (userId, sessionId) {
    const inputParams = {
      userId: userId.toString(),
      sessionId,
      onChallengeCompleted: ({ redemptionToken }) => {
        // eslint-disable-next-line func-names
        $timeout(function () {
          $scope.securityQuestionParameter = {
            sessionId,
            redemptionToken
          };
          // This logic adapted from `handleCaptchaSuccess`.
          const isCrossDeviceLogin =
            $scope.loginFormData.enteredCredentialType === loginConstants.credentialTypes.authToken;
          if (isCrossDeviceLogin) {
            $scope.login(null);
          } else {
            $scope.submit(false, null);
          }
          $scope.securityQuestionParameter = null;
        });
      },
      onChallengeInvalidated: () => {
        // eslint-disable-next-line func-names
        $timeout(function () {
          $scope.securityQuestionParameter = null;
          $scope.login(null);
        });
      },
      onModalChallengeAbandoned: () => {
        // eslint-disable-next-line func-names
        $timeout(function () {
          $scope.loginLayout.isProcessing = false;
        });
      },
      onUnknownError: $scope.showUnknownError
    };
    const event = new CustomEvent('renderSecurityQuestionsChallenge', {
      detail: {
        inputParams
      }
    });
    window.dispatchEvent(event);
  };

  $scope.showIdentityVerificationNoticeModal = function () {
    const modal = modalService.open({
      titleText: languageResource.get('Title.VerificationRequired'),
      bodyText: languageResource.get('Description.VerificationRequired'),
      neutralButtonText: languageResource.get('Action.StartVerification'),
      onNeutral: ReactIdentityVerificationService.startIdentityVerification
    });
    modal.result.then(
      function () {},
      function () {
        ReactIdentityVerificationService.startIdentityVerification();
      }
    );
  };

  $scope.submit = function (fireClickEvent, captchaData) {
    $scope.clearError();

    if (!$scope.loginFormData.credentialValue || !$scope.loginFormData.password) {
      if (!$scope.loginParams.redirectToLoginPageOnError) {
        $scope.loginLayout.error = languageResource.get('Message.UsernameAndPasswordRequired');
      }

      return;
    }

    if (fireClickEvent) {
      $scope.sendInteractionClickEvent(loginConstants.loginSubmitButtonName);
      ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.attempt);

      if ($scope.loginLayout.isFirstSubmit) {
        $scope.loginLayout.isFirstSubmit = false;
        ReactLoginService.utils.incrementEphemeralCounter(loginConstants.counters.firstAttempt);
      }
    }

    $scope.loginLayout.isProcessing = true;
    $scope.loginFormData.enteredCredentialType = loginConstants.credentialTypes.username;

    $scope.login(captchaData);
  };

  $scope.clearError = function () {
    $scope.loginLayout.error = null;
  };

  $scope.getCredentialPlaceholder = function ()
   {
    if ($scope.loginFormData.isDefaultLoginRequired) {
      return languageResource.get('Label.Username');
    }
    return languageResource.get('Label.UsernameEmailPhone');
  };
}

loginModule.controller('loginFormController', loginFormController);

export default loginFormController;
