import loginModule from '../loginModule';

const loginConstants = {
  urls: {
    metaData: '/login/meta-data',
    metaDataV2: '/v2/metadata',
    loginApi: '/v2/login',
    credentialsVerificationApi: '/v2/credentials/verification', // POST - determines if verification email can be sent
    credentialsVerificationSendApi: '/v2/credentials/verification/send', // POST - sends verification email
    loginCaptchaApi: '/captcha/validate/login',
    twoStepVerification: '/login/twostepverification',
    forgotCredentials: '/login/forgot-password-or-username',
    games: '/discover',
    home: '/home',
    login: '/login',
    redirectToFb: '/social/redirect-to-facebook',
    signUpRedirect: '/account/signupredir',
    authTokenMetadata: '/auth-token-service/v1/login/metadata',
    koreaIdVerification: '/id-verification/korea/login'
  },
  templates: {
    verifyEmail: 'verify-email'
  },
  controllers: {
    verifyEmail: 'verifyEmailController'
  },
  errorCodes: {
    unknownError: 0,
    badCredentials: 1,
    captcha: 2,
    accountNotFound: 3,
    passwordResetRequired: 4,
    noPassword: 5,
    accountIssue: 6,
    tooManyAttempts: 7,
    defaultLoginRequired: 9,
    unverifiedCredentials: 10,
    captchaLoadFailed: 11,
    captchaVerifyFailed: 12,
    captchaUnknownError: 13,
    luoBuUserDenied: 14,
    screentimeRestricted: 16,
    securityQuestionRequired: 18,
    securityQuestionFailed: 19
  },
  sendVerificationEmailErrorCodes: {
    tooManyAttempts: 2
  },
  statusCodes: {
    flooded: 429
  },
  retryAttempts: {
    maxInvalidatedChallengeAttempts: 3
  },
  credentialTypes: {
    username: 'Username',
    email: 'Email',
    phone: 'PhoneNumber',
    authToken: 'AuthToken'
  },
  urlQueryNames: {
    errorCode: 'errorCode',
    cType: 'ctype',
    username: 'username'
  },
  counters: {
    prefix: 'WebsiteLogin_',
    firstAttempt: 'FirstAttempt',
    attempt: 'Attempt',
    success: 'Success',
    captcha: 'Captcha',
    passwordResetRequired: 'PasswordResetRequired',
    unverifiedAccount: 'UnverifiedAccount',
    invalidCredentials: 'InvalidCredentials',
    accountNotFound: 'AccountNotFound',
    noPassword: 'NoPassword',
    accountIssue: 'AccountIssue',
    tooManyAttempts: 'TooManyAttempts',
    defaultLoginRequired: 'DefaultLoginRequired',
    redirectToLogin: 'RedirectToLogin',
    captchaLoadFailed: 'CaptchaLoadFailed',
    captchaVerifyFailed: 'CaptchaVerifyFailed',
    captchaUnknownError: 'CaptchaUnknownError',
    luoBuUserDenied: 'LuoBuUserDenied',
    screentimeRestricted: 'ScreenTimeRestricted',
    unknownError: 'UnknownError'
  },
  newLoginQueryString: 'nl=true',
  loginSubmitButtonName: 'loginSubmit',
  quickLoginButtonName: 'loginOtherDevice',
  fbLoginButtonName: 'fbLoginSubmit',
  landingContext: 'landing',
  atSymbol: '@',
  verifyEmailContextSuffix: '_verifyEmail',
  securityQuestionsModalContainerId: 'securityQuestionsModal-container'
};

loginModule.constant('loginConstants', loginConstants);

export default loginConstants;
