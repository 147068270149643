import { TranslationResourceProvider } from 'Roblox';
import angular from "angular";

let login = angular
    .module("login", ["robloxApp", "roblox.formEvents", "modal", "captchaV2", "systemFeedback"])
    .config(["languageResourceProvider", function (languageResourceProvider) {
        var translationProvider = new TranslationResourceProvider();
        var loginResources = translationProvider.getTranslationResource('Authentication.Login');
        var captchaResources = translationProvider.getTranslationResource('Authentication.Captcha');
        var captchaResources = translationProvider.getTranslationResource('Common.Captcha');
        var screentimeResources = translationProvider.getTranslationResource('Feature.Screentime');
        var twoStepVerificationResources = translationProvider.getTranslationResource('Authentication.TwoStepVerification');
        var idVerificationResources = translationProvider.getTranslationResource('Feature.IdVerification');
        languageResourceProvider.setTranslationResources([loginResources, captchaResources, screentimeResources, twoStepVerificationResources, idVerificationResources]);
    }]);

export default login;
