import angular from "angular";
import { importFilesUnderPath, templateCacheGenerator } from "roblox-es6-migration-helper";

import "../../../css/login/login.scss";

import loginModule from "./loginModule.js";

importFilesUnderPath(require.context("./constants/", true, /\.js$/));
importFilesUnderPath(require.context("./services/", true, /\.js$/));
importFilesUnderPath(require.context("./controllers/", true, /\.js$/));
importFilesUnderPath(require.context("./directives/", true, /\.js$/));

let templateContext = require.context("./", true, /\.html$/);
let templates = templateCacheGenerator(angular, "loginAppTemplates", templateContext);

//self manual initialization
angular.element(function () {
    angular.bootstrap("#login-container", [loginModule.name, templates.name]);
});

export default loginModule;