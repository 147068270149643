import loginModule from '../loginModule.js';

function loginForm() {
  return {
    restrict: 'A',
    replace: false,
    templateUrl: 'login-form',
    controller: 'loginFormController',
    scope: {},
    link: function link(scope, element, attrs) {
      scope.loginParams = {
        context: attrs.context, // hardcoded in loginBase.html which is loginPage
        redirectToLoginPageOnError: attrs.redirectToLoginPageOnError === 'true',
        redirectToHomePostLogin: attrs.redirectToHomePostLogin === 'true', // This should be deprecated since we use returnUrl parameters, previously we hardcoded to be false in loginBase.html
        redirectToLoginPageWhenUsernameIsRequired:
          attrs.redirectToLoginPageWhenUsernameIsRequired === 'true',
        includeMessageBanner: attrs.includeMessageBanner === 'true', // hardedcoded in loginBase.html
        returnUrl: attrs.returnUrl,
        enableReactApp: attrs.enableReactApp === 'true'
      };
    }
  };
}

loginModule.directive('loginForm', loginForm);

export default loginForm;
