import loginModule from '../loginModule.js';
import {
  ReactLoginService,
} from 'Roblox';
function loginBase() {
  return {
    restrict: 'A',
    replace: false,
    templateUrl: 'login-base',
    controller: 'loginController',
    link: function link(scope, element, attrs) {
      scope.loginBaseParams = {
        returnUrl: attrs.returnUrl,
        // We can turn on react version from url param even backend setting points to angular.
        // v = version r = react
        // This will be cleaned up all together when react version is released.
        // TODO: clean up.
        enableReactApp: ReactLoginService.utils.getUrlParamValue('v') === 'r'
      };
    }
  };
}

loginModule.directive('loginBase', loginBase);

export default loginBase;
