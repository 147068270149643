import loginModule from '../loginModule.js';

function loginFormReact() {
  return {
    restrict: 'A',
    replace: false,
    templateUrl: 'login-form-react',
    scope: {},
    link: function link(scope, element, attrs) {
      scope.loginParams = {
        returnUrl: attrs.returnUrl
      };
    }
  };
}

loginModule.directive('loginFormReact', loginFormReact);

export default loginFormReact;
