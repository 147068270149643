import { EnvironmentUrls } from 'Roblox';
import loginModule from '../loginModule.js';

function loginService(httpService, loginConstants) {
  'ngInject';

  function getApiUrl(endpoint) {
    return EnvironmentUrls.authApi + endpoint;
  }

  function login(params) {
    const url = getApiUrl(loginConstants.urls.loginApi);
    const urlConfig = {
      url
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  function verifyCredentials(params) {
    const url = getApiUrl(loginConstants.urls.credentialsVerificationSendApi);
    const urlConfig = {
      url
    };

    return httpService.httpPost(urlConfig, params, true);
  }

  function getReturnUrl() {
    return window.Roblox.Auth && window.Roblox.Auth.returnUrl;
  }

  function loginWithVerificationToken(userId, challengeId, verificationToken, rememberDevice) {
    return httpService.httpPost({
      url: `${EnvironmentUrls.authApi}/v3/users/${userId}/two-step-verification/login`
      }, {
        challengeId: challengeId,
        verificationToken: verificationToken,
        rememberDevice: rememberDevice
      });
  }
  
  const loginService = {
    login,
    getReturnUrl,
    loginWithVerificationToken,
    verifyCredentials
  };
  return loginService;
}

loginModule.factory('loginService', loginService);

export default loginService;
